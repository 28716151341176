import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Box, Button, Flex, VStack, Text, useColorMode, useBreakpointValue, Badge, HStack, SimpleGrid, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, IconButton } from '@chakra-ui/react';
import { FaArrowLeft, FaPlus, FaLeaf, FaHamburger, FaMinus, FaTrash, FaEdit, FaEllipsisV } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import Sidebar from '../components/Sidebar';
import TableDetailsLoader from './TableDetailsLoader';
import './TableDetailsPage.css';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';

const MotionBox = motion(Box);
const MotionModalContent = motion(ModalContent);

function TableDetailsPage() {
  const history = useHistory();
  const { id: tableId } = useParams();
  const location = useLocation();

  const [order, setOrder] = useState(location.state?.order || null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOrderItem, setSelectedOrderItem] = useState(null);
  const [isEditOrderSheetVisible, setIsEditOrderSheetVisible] = useState(false);
  const { colorMode } = useColorMode();
  const displayType = useBreakpointValue({ base: 'bottom', md: 'sidebar' });
  const toast = useToast();
  const bookingId = tableId.slice(4);
  const token = useSelector((state) => state.auth.token);
  const staffId = useSelector((state) => state.auth.employeeId);
  const [numberPart, setNumberPart] = useState('');
  const [reserveId, setReserveId] = useState(null);
const [id, setId] = useState(null)
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const cancelRef = React.useRef();

  useEffect(() => {
    console.log('Current URL:', window.location.href);
    const numberMatch = window.location.href.match(/number(\d+)/);
    if (numberMatch) {
      setNumberPart(numberMatch[1]);
    }
  }, []);
 
  useEffect(() => {
    const storedEmployeeId = localStorage.getItem('employeeId');
    console.log('Employee ID from localStorage:', storedEmployeeId);
  }, []);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      setIsLoading(true);
      try {
        let response;
        const currentURL = window.location.href;

        const orderIdMatch = currentURL.match(/orderId=(\d+)/);
        const orderMatch = currentURL.match(/order=(\d+)/);
        const numberMatch = currentURL.match(/number(\d+)/);
        const idMatch = currentURL.match(/id(\d+)/);
        const reservationIdMatch = currentURL.match(/reservationId=(\d+)/);

        if (reservationIdMatch) {
          const reservationId = reservationIdMatch[1];
          setReserveId(reservationId);
          console.log('Reservation ID:', reservationId);
        }

        if (idMatch) {
          const id = idMatch[1];
          setId(id);
          console.log('ID:', id);
        }

        if (orderIdMatch) {
          const orderId = orderIdMatch[1];
          console.log('Order ID:', orderId);

          response = await axios.get(
            `https://api.hotelmadhuban.co.in/waiter/order/getById?id=${orderId}`,
            {
              headers: { 'Authorization': `Bearer ${token}` }
            }
          );
          console.log('Response:', response);
          setReserveId(response.data.reservationId);
        } else if (orderMatch) {
          const orderNumber = orderMatch[1];
          console.log('Order number:', orderNumber);

          response = await axios.get(
            `https://api.hotelmadhuban.co.in/waiter/order/getById?id=${orderNumber}`,
            {
              headers: { 'Authorization': `Bearer ${token}` }
            }
          );
          console.log('Response from orderMatch:', response.data);
          setReserveId(response.data.reservationId);
        } else if (numberMatch) {
          const phoneNumber = numberMatch[1];
          console.log('Phone Number:', phoneNumber);

          const bookingResponse = await axios.get(
            `https://api.hotelmadhuban.co.in/hotel/getCheckedInBookings`,
            {
              params: {
                username: 'MadhubanAdmin',
                password: 'Hotel2024admin'
              },
              headers: { 'Authorization': `Bearer ${token}` }
            }
          );
          
          const booking = bookingResponse.data.find(b => b.phoneNumber === parseInt(phoneNumber));
          
          if (booking) {
            response = await axios.get(
              `https://api.hotelmadhuban.co.in/waiter/order/getForHotel?id=${booking.bookingId}`,
              {
                headers: { 'Authorization': `Bearer ${token}` }
              }
            );

            // Create the correct payload for createForHotel
            const hotelOrderPayload = {
              hotelBookingId: booking.bookingId,
              phone: booking.phoneNumber,
              roomNumber: booking.roomNumber,
              staffId: 1
            };

            console.log('Response from getForHotel:', response);
            setReserveId(response.data.reservationId);
          }
        }

        if (response.data[0] === undefined) {
          setOrder(response.data);
        } else {
          setOrder(response.data[0]);
        }
      } catch (error) {
        console.error('Failed to fetch order details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrderDetails();
  }, [tableId, token, toast]);

  const handleCheckout = async () => {
    history.push(`/reception-bill/${order.id}`);
  };

  const handleAddOrder = () => {
    const existingTotal = order ? order.totalOrderAmount : 0;
    const existingOrderID = order[0]?.id || null;

    history.push({
      pathname: '/menu',
      search: `?tableId=${tableId}&staffId=${staffId}&number=${numberPart}&reservationId=${reserveId}&orderId=${order.id}&tableNo=${order.tableNumber}&id=${id}&existingOrderId=${existingOrderID}`,
      state: {
        existingOrder: order,
        existingTotal: existingTotal,
        tableId: tableId,
        staffId: staffId,
        numberPart: numberPart,
      }
    });
  };

  const handleEditOrder = (orderItem) => {
    setSelectedOrderItem(orderItem);
    setIsEditOrderSheetVisible(true);
  };

  const handleOrderUpdate = async (updatedItem) => {
    try {
      const response = await axios.put(
        `https://api.hotelmadhuban.co.in/order-item/edit?id=${updatedItem.id}`,
        {
          menuItemId: updatedItem.menuItem.id,
          quantity: updatedItem.quantity,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data === 'Edited Successfully!') {
        const updatedOrderItems = order.orderItems.map(item =>
          item.id === updatedItem.id ? updatedItem : item
        );
        setOrder({ ...order, orderItems: updatedOrderItems });
        toast({
          title: "Order Updated",
          description: "The order has been successfully updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error('Failed to update the order');
      }
    } catch (error) {
      console.error('Failed to update order:', error);
      toast({
        title: "Failed to update order",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsEditOrderSheetVisible(false);
  };

  const handleDeleteOrderItem = async (orderItem) => {
    try {
      const response = await axios.delete(
        `https://api.hotelmadhuban.co.in/order-item/delete?orderId=${order.id}&orderItemId=${orderItem.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data === 'Object Deleted!') {
        setOrder(prevOrder => ({
          ...prevOrder,
          orderItems: prevOrder.orderItems.filter(item => item.id !== orderItem.id)
        }));
        toast({
          title: "Order item deleted successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error('Failed to delete the order item');
      }
    } catch (error) {
      console.error('Failed to delete order item:', error);
      toast({
        title: "Failed to delete order item",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsEditOrderSheetVisible(false);
  };

  const handleBack = () => {
    history.push('/table-list');
  };

const formatTime = (time24) => {
  let [hours, minutes] = time24.split(':');
  let period = 'AM';
  hours = parseInt(hours, 10);
  if (hours >= 12) {
    period = 'PM';
    if (hours > 12) {
      hours -= 12;
    }
  }
  if (hours === 0) {
    hours = 12;
  }
  return `${hours}:${minutes.padStart(2, '0')} ${period}`;
};

  if (!order) {
    return <Text>No order found for this table.</Text>;
  }

  const handleDeleteClick = () => {
    setIsDeleteAlertOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(
        `https://api.hotelmadhuban.co.in/waiter/order/delete?id=${order.id}`,
        {
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );
      toast({
        title: "Order Deleted",
        description: "The order has been successfully deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      history.push('/table-list');
    } catch (error) {
      console.error('Failed to delete order:', error);
      toast({
        title: "Failed to delete order",
        description: "An error occurred while deleting the order.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsDeleteAlertOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteAlertOpen(false);
  };

  return (
    <Flex minHeight="100vh">
      <Sidebar />
      <Box
        flex="1"
        p={4}
        bg={colorMode === 'dark' ? 'gray.800' : 'gray.50'}
        ml={{ base: 0, md: '250px' }}
        pb={{ base: '60px', md: 0 }}
      >
        <Box p={4}>
          <Flex align="center" mb={4}>
            <IconButton
              icon={<FaArrowLeft />}
              onClick={handleBack}
              aria-label="Back"
              variant="ghost"
              color="current"
              _hover={{ bg: 'gray.200' }}
              mr={4}
            />
            <Text
              fontSize="3xl"
              fontWeight="bold"
              color={colorMode === 'dark' ? 'purple.400' : 'purple.600'}
            >
              Order Details
            </Text>
          </Flex>
          <Flex justify="space-between" align="center" mb={6}>
            <Box>
              <Text fontSize="xl" fontWeight="bold">
                {order.tableNumber ? `Table ${order.tableNumber}` : `Room ${bookingId}`}
              </Text>
              <Badge colorScheme={order.tableStatus === 'occupied' ? 'red' : 'green'}>
                {order.tableStatus || 'N/A'}
              </Badge>
            </Box>
            <Flex>
              <Button colorScheme="teal" leftIcon={<FaPlus />} onClick={handleAddOrder} mr={3}>
                Add Order
              </Button>
              <Button colorScheme="teal" onClick={handleCheckout} disabled={!order.orderItems || order.orderItems.length === 0}>
                Checkout
              </Button>
              <Menu>
                <MenuButton
                  as={IconButton}
                  icon={<FaEllipsisV />}
                  variant="ghost"
                  aria-label="More options"
                />
                <MenuList>
                  <MenuItem onClick={handleDeleteClick}>Delete Order</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Flex>
          <AnimatePresence>
            {order.orderItems && order.orderItems.length > 0 && (
              <MotionBox
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                <Box
                  bg={colorMode === 'dark' ? 'gray.700' : 'white'}
                  borderRadius="lg"
                  overflow="hidden"
                  boxShadow="md"
                  position="relative"
                  _before={{
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '4px',
                    bgGradient: 'linear(to-r, purple.500, cyan.500)',
                  }}
                >
                  <Box p={4}>
                    <Flex justify="space-between" align="center" mb={2}>
                      <Text fontWeight="bold">Order #{order.id}</Text>
                      <Text fontSize="sm" color={colorMode === 'dark' ? 'gray.400' : 'gray.600'}>
                        {order.orderDate} {formatTime(order.orderTime)}
                      </Text>
                    </Flex>
                    <VStack align="stretch" spacing={2}>
                      {order.orderItems.map((item, index) => (
                        <Flex
                          key={item.id}
                          justify="space-between"
                          align="center"
                          bg={colorMode === 'dark' ? 'gray.600' : 'gray.100'}
                          p={2}
                          borderRadius="md"
                        >
                          <HStack>
                            <Text>{item.menuItem.dietInfo === 'Vegetarian' ? <FaLeaf /> : <FaHamburger />}</Text>
                            <VStack align="start" spacing={0}>
                              <Text>{item.menuItem.name}</Text>
                              <Text fontSize="sm" color={colorMode === 'dark' ? 'gray.400' : 'gray.600'}>
                                x {item.quantity}
                              </Text>
                            </VStack>
                          </HStack>
                          <VStack align="end" spacing={0}>
                            <Text fontWeight="bold">₹{item.totalPerItem}</Text>
                            <Button size="xs" onClick={() => handleEditOrder(item)}>
                              Edit
                            </Button>
                          </VStack>
                        </Flex>
                      ))}
                    </VStack>
                    <Text fontWeight="bold" textAlign="right" mt={4}>
                      Grand Total: ₹{order.totalOrderAmount.toFixed(2)}
                    </Text>
                  </Box>
                </Box>
              </MotionBox>
            )}
          </AnimatePresence>
        </Box>
      </Box>
      <Modal
        isOpen={isEditOrderSheetVisible}
        onClose={() => setIsEditOrderSheetVisible(false)}
        isCentered
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <AnimatePresence>
          {isEditOrderSheetVisible && selectedOrderItem && (
            <MotionModalContent
              initial={{ y: "100%" }}
              animate={{ y: 0 }}
              exit={{ y: "100%" }}
              transition={{ type: "spring", damping: 25, stiffness: 500 }}
              borderTopRadius="20px"
              bg={colorMode === 'dark' ? 'gray.800' : 'white'}
            >
              <ModalHeader borderBottomWidth="1px">Edit Order</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack spacing={6} align="stretch">
                  <HStack justify="space-between">
                    <Text fontSize="lg" fontWeight="bold">{selectedOrderItem.menuItem?.name || 'Unknown Item'}</Text>
                    <HStack>
                      <IconButton
                        icon={<FaMinus />}
                        onClick={() => {
                          if (selectedOrderItem.quantity > 1) {
                            setSelectedOrderItem({
                              ...selectedOrderItem,
                              quantity: selectedOrderItem.quantity - 1
                            });
                          }
                        }}
                        isDisabled={selectedOrderItem.quantity <= 1}
                        variant="outline"
                      />
                      <Text fontSize="lg" fontWeight="bold">{selectedOrderItem.quantity}</Text>
                      <IconButton
                        icon={<FaPlus />}
                        onClick={() => {
                          setSelectedOrderItem({
                            ...selectedOrderItem,
                            quantity: selectedOrderItem.quantity + 1
                          });
                        }}
                        variant="outline"
                      />
                    </HStack>
                  </HStack>
                  <HStack justify="space-between">
                    <Button
                      onClick={() => handleOrderUpdate(selectedOrderItem)}
                      colorScheme="blue"
                      flex={1}
                    >
                      Save Changes
                    </Button>
                    <IconButton
                      icon={<FaTrash />}
                      onClick={() => handleDeleteOrderItem(selectedOrderItem)}
                      colorScheme="red"
                      aria-label="Delete item"
                    />
                  </HStack>
                </VStack>
              </ModalBody>
            </MotionModalContent>
          )}
        </AnimatePresence>
      </Modal>
      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleDeleteCancel}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Order
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this order? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={handleDeleteCancel}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteConfirm} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}

export default TableDetailsPage;