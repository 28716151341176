import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
  Box,
  Flex,
  VStack,
  HStack,
  Text,
  Button,
  IconButton,
  useColorMode,
  useColorModeValue,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react';
import { FaArrowLeft, FaMinus, FaPlus } from 'react-icons/fa';
import Sidebar from '../components/Sidebar';

const OrderDetailsPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [orderItems, setOrderItems] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = useSelector((state) => state.auth.token);
  const { colorMode } = useColorMode();
  const toast = useToast();
  const displayType = useBreakpointValue({ base: 'bottom', md: 'sidebar' });
  const [number, setNumber] = useState('');
  const [reservationDate, setReservationDate] = useState('');
  const [reservationStartTime, setReservationStartTime] = useState('');
  const [reservationEndTime, setReservationEndTime] = useState('');
  const { tableId, itemsInCart, roomDetails, selectedTableType } = location.state || {};
  const [existingOrder, setExistingOrder] = useState(null);
  const grandTotalBgColor = useColorModeValue('purple.100', 'purple.900');
  const [isTakeaway, setIsTakeaway] = useState(false);
  const [id, setId] = useState(null)
  const [existingOrderId, setExistingOrderId] = useState(null);


  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const cardBgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const accentColor = useColorModeValue('purple.600', 'purple.300');

  useEffect(() => {
    const currentURL = window.location.href;
    console.log('currentURL', currentURL);

    const numberMatch = currentURL.match(/number(\d+)/);
    if (numberMatch) {
      const numberPart = numberMatch[1];
      setNumber(numberPart);
      console.log('Numeric part next to "number":', numberPart);
    }
    const idMatch = currentURL.match(/id(\d+)/);
    if (idMatch) {
      const idPart = idMatch[1];
      setId(idPart);
      console.log('ID:', idPart);
    }
    const existingOrderIdMatch = currentURL.match(/existingOrderId(\d+)/);

    if (existingOrderIdMatch) {
      const existingOrderId = existingOrderIdMatch[1];
      console.log('Existing Order ID:', existingOrderId);
      //setExistingOrderId(existingOrderId); // Assuming you have a state variable for existingOrderId
    }
  }, []);

  useEffect(() => {
    const currentURL = window.location.href;
    const queryParams = new URLSearchParams(window.location.search);
    const reservationId = queryParams.get('reservationId');
    const orderId = queryParams.get('orderId');
    const orderType = queryParams.get('type');
    const orderTypeId = queryParams.get('id');
    // const existingOrderIdMatch = queryParams.get('existingOrderId')
    setIsTakeaway(orderType === 'takeaway');
    console.log('currentURL', currentURL);
    console.log('Reservation ID:', reservationId);
    console.log('Order ID:', orderId);
    console.log('Order Type:', orderType);
    console.log('Is Takeaway:', orderType === 'takeaway');
    console.log('id', orderTypeId)
    //console.log('existingOrderId', existingOrderIdMatch)

    const existingOrderIdMatch = currentURL.match(/existingOrderId(\d+)/);
    const existingOrderId = existingOrderIdMatch ? existingOrderIdMatch[1] : null;

    console.log('existingOrderId ', existingOrderId);

    const numberMatch = currentURL.match(/number(\d+)/);
    if (numberMatch) {
      const numberPart = numberMatch[1];
      setNumber(numberPart);
      console.log('Numeric part next to "number":', numberPart);
    }

    if (!reservationId || !orderId || reservationId === 'null' || orderId === 'null') {
      const fetchExistingOrder = async () => {
        try {
          const response = await axios.get(
            `https://api.hotelmadhuban.co.in:443/waiter/order/getByTableAndStaff?tableId=${tableId}&staffId=9`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const orderData = response.data;
          if (orderData.id !== null && orderData.reservationId !== null) {
            setExistingOrder(orderData);
          }
        } catch (error) {
          console.error('Error fetching existing order:', error);
        }
      };

      const fetchRoomOrder = async () => {
        try {
          const response = await axios.get(
            `https://api.hotelmadhuban.co.in:443/waiter/order/getForHotel?id=${tableId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const orderData = response.data;
          if (orderData.length > 0) {
            setExistingOrder(orderData[0]);
          }
        } catch (error) {
          console.error('Error fetching room order:', error);
        }
      };

      if (tableId.startsWith('room')) {
        fetchRoomOrder();
      } else {
        fetchExistingOrder();
      }
    }
  }, [tableId, token]);

  useEffect(() => {
    const currentDate = new Date().toISOString().slice(0, 10);
    const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    const endTime = new Date(new Date().getTime() + 2 * 60 * 60 * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

    setReservationDate(currentDate);
    setReservationStartTime(currentTime);
    setReservationEndTime(endTime);
  }, []);

  useEffect(() => {
    const { itemsInCart, menuItems } = location.state || {};

    if (itemsInCart && menuItems) {
      const initialOrderItems = itemsInCart.map((cartItem) => {
        const menuItem = menuItems.find((mi) => mi.id === cartItem.id);
        return {
          ...menuItem,
          quantity: cartItem.quantity,
        };
      });

      setOrderItems(initialOrderItems);
      calculateGrandTotal(initialOrderItems);
    } else {
      console.error('Data not available');
    }
  }, [location.state]);

  const handleBackClick = () => {
    history.goBack();
  };

  const handleQuantityChange = (itemId, operation) => {
    setOrderItems((prevItems) => {
      const itemIndex = prevItems.findIndex((item) => item.id === itemId);
      const updatedItems = [...prevItems];

      if (itemIndex !== -1) {
        if (operation === 'increase') {
          updatedItems[itemIndex].quantity++;
        } else if (operation === 'decrease') {
          if (updatedItems[itemIndex].quantity === 1) {
            const shouldRemove = window.confirm('Do you want to remove this item from the order?');
            if (shouldRemove) {
              updatedItems.splice(itemIndex, 1);
            }
          } else {
            updatedItems[itemIndex].quantity--;
          }
        }
      }

      calculateGrandTotal(updatedItems);
      return updatedItems;
    });
  };

  const calculateGrandTotal = (items) => {
    const newGrandTotal = items.reduce((total, item) => total + getPrice(item) * item.quantity, 0);
    setGrandTotal(newGrandTotal);
  };

  const addItemsToOrder = async (orderId, orderItemsData) => {
    try {
      const response = await axios.put(
        `https://api.hotelmadhuban.co.in:443/waiter/order/addItems?id=${orderId}`,
        orderItemsData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error('Error adding items to order:', error);
      throw error;
    }
  };

  const handleConfirmOrder = async () => {
    try {
      const currentURL = window.location.href;
      const numberMatch = currentURL.match(/number(\d+)/);
      const roomNumberMatch = currentURL.match(/roomnumber(\d+)/);
      const queryParams = new URLSearchParams(window.location.search);
      const reservationId = queryParams.get('reservationId');
      let orderId = queryParams.get('orderId');
      const existingOrderIdMatch = queryParams.get('existingOrderId');

      // If orderId is undefined, use id instead
      if (!orderId || orderId === 'undefined') {
        orderId = id;
      }

      console.log('existingOrderIdMatch -->', existingOrderIdMatch);
      console.log('orderId -->', orderId);

      if (numberMatch && roomNumberMatch) {
        console.log('trigger')
        const phoneNumber = numberMatch[1];
        const roomNumber = roomNumberMatch[1];

        // First get the booking details using checked-in bookings API
        const bookingResponse = await axios.get(
          `https://api.hotelmadhuban.co.in/hotel/getCheckedInBookings`,
          {
            params: {
              username: 'MadhubanAdmin',
              password: 'Hotel2024admin'
            },
            headers: { 'Authorization': `Bearer ${token}` }
          }
          );
          
          // Find the booking with matching phone number
          const booking = bookingResponse.data.find(b => b.phoneNumber === parseInt(phoneNumber));
          
          if (booking && existingOrderId == null && orderId == null) {
            const hotelOrderPayload = {
              hotelBookingId: booking.bookingId,  // Use the actual booking ID
              phone: booking.phoneNumber,
              roomNumber: booking.roomNumber,  // Use the actual room number
              staffId: 1
            };

            const response = await axios.post(
              'http://ec2-54-253-7-206.ap-southeast-2.compute.amazonaws.com:8081/waiter/order/createForHotel',
              hotelOrderPayload,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
              },
              }
              );

              console.log('Hotel order created:', response.data);
              const newOrderId = response.data;
              await addItemsToOrder(newOrderId, {
                orderItemDto: itemsInCart.map((item) => ({
                  menuItemId: item.id,
                  menuId: 3,
                  specialInstructions: '',
                  quantity: item.quantity,
                })),
              });
              toast({
                title: "Success",
                description: "Hotel order created successfully!",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              history.push('/table-list');
          } else {
            const newOrderId = existingOrderIdMatch === null ? orderId : (orderId === null ? existingOrderIdMatch : undefined);
           // const newOrderId = existingOrderId
            await addItemsToOrder(newOrderId, {
              orderItemDto: itemsInCart.map((item) => ({
                  menuItemId: item.id,
                  menuId: 3,
                  specialInstructions: '',
                  quantity: item.quantity,
                })),
              });
              history.push('/table-list');
          }
      } else if (isTakeaway) {
        const takeawayPayload = {
          takeawayOrderDto: {
            specialInstructions: "" // You might want to add a field for this in your UI
          },
          orderItemsList: {
            orderItemDto: itemsInCart.map(item => ({
              menuId: 1, // Assuming all items have the same menuId, adjust if needed
              menuItemId: item.id,
              quantity: item.quantity,
              specialInstructions: "" // You might want to add a field for this in your UI
            }))
          }
        };

        const response = await axios.put(
          'https://api.hotelmadhuban.co.in/take-away/createAndAddItems',
          takeawayPayload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        console.log('Takeaway order created:', response.data);
        toast({
          title: "Success",
          description: "Takeaway order created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        history.push('/table-list'); // Or wherever you want to redirect after creating a takeaway order
      } else if (tableId.startsWith('room')) {
        // Handle room service order
        
        const createResponse = await axios.put(
          'https://api.hotelmadhuban.co.in/take-away/createAndAddItems',
          {
            takeawayOrderDto: {
              specialInstructions: "" // You might want to add a field for this in your UI
            },
            orderItemsList: {
              orderItemDto: itemsInCart.map(item => ({
                menuId: 1, // Assuming all items have the same menuId, adjust if needed
                menuItemId: item.id,
                quantity: item.quantity,
                specialInstructions: "" // You might want to add a field for this in your UI
              }))
            }
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        // history.push('/table-list');
        const newOrderId = createResponse.data.id;
        console.log('newOrderId -->', newOrderId)
        await addItemsToOrder(newOrderId, {
          orderItemDto: itemsInCart.map((item) => ({
            menuItemId: item.id,
            menuId: 3,
            specialInstructions: '',
            quantity: item.quantity,
          })),
        });
        console.log('Order and items added successfully for room');
        toast({
          title: "Success",
          description: "Room service order created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        history.push(`/table-list`);
      } else if (orderId && orderId !== 'null') {
        // Update existing order
        const existingOrderResponse = await axios.get(
          `https://api.hotelmadhuban.co.in:443/waiter/order/getById?id=${orderId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const existingOrder = existingOrderResponse.data;

        // Update existing items and add new items
        const updatedItems = await updateExistingItems(existingOrder, itemsInCart);
        const newItems = getNewItems(existingOrder, itemsInCart);

        if (newItems.length > 0) {
          await addItemsToOrder(orderId, { orderItemDto: newItems });
        }

        console.log('Order updated successfully');
        toast({
          title: "Success",
          description: "Order updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        history.push(`/table/${tableId}?orderId=${orderId}`);
      } else {
        // Create new table order
        const reservationData = {
          customerDto: {
            firstName: 'ABCD',
            lastName: 'PQRS',
            phone: '1234',
          },
          tableId: parseInt(tableId),
          reservationDate,
          reservationStartTime,
          reservationEndTime,
          numberOfGuests: 5,
        };

        const reservationResponse = await axios.post(
          'https://api.hotelmadhuban.co.in:443/reservation/create',
          reservationData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        const newReservationId = reservationResponse.data;
        const orderData = {
          orderDate: reservationDate,
          orderTime: reservationStartTime,
          reservationId: newReservationId,
          tableId: parseInt(tableId),
          staffId: 2,
          customerDto: {
            id: 1,
            phone: 1234,
          },
        };

        const orderResponse = await axios.post(
          'https://api.hotelmadhuban.co.in:443/waiter/order/create',
          orderData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        const newOrderId = orderResponse.data;
        await addItemsToOrder(newOrderId, {
          orderItemDto: itemsInCart.map((item) => ({
            menuItemId: item.id,
            menuId: 3,
            specialInstructions: '',
            quantity: item.quantity,
          })),
        });
        console.log('Reservation, order, and items added successfully');
        toast({
          title: "Success",
          description: "Order created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        history.push(`/table/${tableId}?orderId=${newOrderId}`);
      }
    } catch (error) {
      console.error('Error confirming order:', error);
      toast({
        title: "Error",
        description: "An error occurred while confirming the order. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const updateExistingItems = async (existingOrder, newItems) => {
    for (const existingItem of existingOrder.orderItems) {
      const newItem = newItems.find(item => item.id === existingItem.menuItem.id);
      if (newItem) {
        const updatedQuantity = existingItem.quantity + newItem.quantity;
        await axios.put(
          `https://api.hotelmadhuban.co.in/order-item/edit?id=${existingItem.id}`,
          {
            menuItemId: existingItem.menuItem.id,
            quantity: updatedQuantity,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
      }
    }
  };

  const getNewItems = (existingOrder, newItems) => {
    return newItems.filter(newItem =>
      !existingOrder.orderItems.some(existingItem => existingItem.menuItem.id === newItem.id)
    ).map(item => ({
      menuItemId: item.id,
      menuId: 3,
      specialInstructions: '',
      quantity: item.quantity,
    }));
  };

  const getPrice = (item) => {
    switch (selectedTableType) {
      case 'ac':
        return Number(item.acPrice).toFixed(2);
      case 'upper':
        return Number(item.upperPrice).toFixed(2);
      case 'main':
      default:
        return Number(item.mainPrice).toFixed(2);
    }
  };

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text>Error: {error}</Text>;
  }

  return (
    <Flex minHeight="100vh">
      <Sidebar />
      <Box
        flex="1"
        p={4}
        bg={bgColor}
        ml={{ base: 0, md: '250px' }}
        transition="all 0.3s ease-in-out"
        pb={{ base: '60px', md: 0 }}
      >
        <VStack spacing={4} align="stretch">
          <Flex align="center" mb={4}>
            <IconButton
              icon={<FaArrowLeft />}
              onClick={handleBackClick}
              aria-label="Back"
              variant="ghost"
              mr={4}
            />
            <Text
              fontSize="2xl"
              fontWeight="bold"
              bgGradient="linear(to-r, #6c5ce7, #00cec9)"
              bgClip="text"
            >
              Order Details
            </Text>
          </Flex>

          <Box bg={cardBgColor} borderRadius="lg" p={4} boxShadow="md">
            <VStack spacing={4} align="stretch">
              {orderItems.map((item) => (
                <Flex key={item.id} justify="space-between" align="center">
                  <Text fontWeight="medium">{item.name}</Text>
                  <HStack>
                    <Text color={accentColor}>₹{getPrice(item)}</Text>
                    <IconButton
                      size="sm"
                      icon={<FaMinus />}
                      onClick={() => handleQuantityChange(item.id, 'decrease')}
                      isDisabled={item.quantity === 1}
                    />
                    <Text>{item.quantity}</Text>
                    <IconButton
                      size="sm"
                      icon={<FaPlus />}
                      onClick={() => handleQuantityChange(item.id, 'increase')}
                    />
                  </HStack>
                </Flex>
              ))}
            </VStack>
          </Box>

          <Flex justify="space-between" align="center" p={4} bgGradient="linear(to-r, #6c5ce7, #00cec9)" borderRadius="lg">
            <Text fontWeight="bold" style={{ color: 'white' }}>Grand Total:</Text>
            <Text fontWeight="bold" style={{ color: 'white' }}>₹{grandTotal.toFixed(2)}</Text>
          </Flex>

          <Button bg="#6c5ce7"
            color="white"
            _hover={{ bg: "#5b50b1" }} size="lg" onClick={handleConfirmOrder}>
            Confirm Order
          </Button>
        </VStack>
      </Box>
    </Flex>
  );
};

export default OrderDetailsPage;