import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
  Box,
  Button,
  Flex,
  Text,
  VStack,
  HStack,
  useColorMode,
  useBreakpointValue,
  IconButton,
  Select,
  Input,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Badge,
  Divider,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Progress,
  ModalFooter,
  SimpleGrid,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Card,
  CardBody,
  Icon,
  useColorModeValue,
  Tag,
  Tooltip,
} from '@chakra-ui/react';
import { FaArrowLeft, FaPrint, FaLeaf, FaHamburger, FaEdit, FaMinus, FaPlus, FaTrash, FaClock, FaTable, FaEllipsisV, FaUser, FaPhone, FaBed, FaCalendarAlt, FaPencilAlt, FaUsers } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import Sidebar from '../components/Sidebar';
import PrintBill from './PrintBill';
import BillingModal from '../pages/BillingModel'; // Import BillingModal
import { message } from 'antd';
import { format, parse } from 'date-fns';

const MotionBox = motion(Box);
const MotionModalContent = motion(ModalContent);

function BillPage({ isReception = false }) {
  const history = useHistory();
  const { id: orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { colorMode } = useColorMode();
  const displayType = useBreakpointValue({ base: 'stack', md: 'side-by-side' });
  const [selectedOrderItem, setSelectedOrderItem] = useState(null);
  const [isEditOrderSheetVisible, setIsEditOrderSheetVisible] = useState(false);
  const [paymentMode, setPaymentMode] = useState('cash');
  const [amountPaid, setAmountPaid] = useState('');
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [role, setRole] = useState('');
  const [checkedInBookings, setCheckedInBookings] = useState([]);
  const [isRoomSelectionOpen, setIsRoomSelectionOpen] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);

  const [hotelData, setHotelData] = useState([])
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false); // State to manage BillingModal visibility

  const token = useSelector((state) => state.auth.token);
  const printBillRef = useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const [bookingDetails, setBookingDetails] = useState(null);
  const [isAddToHotelEnabled, setIsAddToHotelEnabled] = useState(false);

  const isHotelOrder = order && order.hotelBookingId;
  const isTakeaway = order && order.isTakeaway;

  const [isHotelSearchModalOpen, setIsHotelSearchModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9; // Show 9 cards per page (3x3 grid)

  const cardBg = useColorModeValue('white', 'gray.700');
  const cardBorderColor = useColorModeValue('gray.200', 'gray.600');
  const selectedCardBg = useColorModeValue('blue.100', 'blue.700');
  const modalBg = useColorModeValue('white', 'gray.800');

  const bookingIdColor = useColorModeValue('purple.500', 'purple.300');
  const roomNumberColor = useColorModeValue('green.500', 'green.300');
  const groupColor = useColorModeValue('blue.500', 'blue.300');

  const isBillFullyPaid = order && (order.paidAmount + order.transferredToHotel) >= order.totalOrderAmount;
  const isBillFullyTransferred = order && order.transferredToHotel === order.totalOrderAmount;
  const isPendingAmount = order && order.pendingAmount > 0;

  useEffect(() => {
    const storedRole = localStorage.getItem('role');
    setRole(storedRole);
    console.log('Employee ID from localStorage:', storedRole);
  }, []);

  const fetchOrderDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://api.hotelmadhuban.co.in:443/waiter/order/getById?id=${orderId}`,
        {
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );
      setOrder(response.data);
      setAmountPaid(response.data.paidAmount.toString());
    } catch (error) {
      console.error('Failed to fetch order details:', error);
      toast({
        title: "Error",
        description: "Failed to fetch bill details. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [orderId, token, toast]);

  useEffect(() => {
    if (order && order.hotelBookingId) {
      fetchBookingDetails(order.hotelBookingId);
    }
  }, [order]);


  const fetchBookingDetails = async (bookingId) => {
    try {
      const response = await axios.get(
        `https://api.hotelmadhuban.co.in:443/hotel/getBookingById`,
        {
          params: {
            username: 'MadhubanAdmin',
            password: 'Hotel2024admin',
            id: bookingId,
          },
          headers: { 'Authorization': `Bearer ${token}` }

        }
      );
      setBookingDetails(response.data);
      if (response.data.foodAmount < order.totalOrderAmount) {
        setIsAddToHotelEnabled(true);
      } else {
        setIsAddToHotelEnabled(false);
      }
    } catch (error) {
      console.error('Error fetching booking details:', error);
      toast({
        title: "Error",
        description: "Failed to fetch booking details. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };




  const handleBack = () => {
    history.goBack();
  };

  const handleCheckout = async () => {
    console.log('order', order)
    try {
      const response = await axios.put(
        `https://api.hotelmadhuban.co.in:443/reservation/check-out?id=${order.reservationId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data === 'Checked Out Successfully!') {
        history.push('/table-list');
      } else {
        throw new Error('Failed to generate the bill');
      }
    } catch (error) {
      console.error('Failed to generate the bill. Please try again.');
    }
  };

  const formatOrderTime = (timeString) => {
    console.log('timeString', timeString);
    if (!timeString) return '';

    try {
      // Handling time strings with fractional seconds
      const timeWithoutFractionalSeconds = timeString.split('.')[0];
      const parsedTime = parse(timeWithoutFractionalSeconds, 'HH:mm:ss', new Date());
      return format(parsedTime, 'hh:mm a');
    } catch (error) {
      console.error('Invalid time format:', error);
      return '';
    }
  };


  const handlePayment = async () => {
    if (!order || !order.reservationId || !order.id) {
      toast({
        title: "Error",
        description: "Invalid order data. Unable to process payment.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.put(
        'https://api.hotelmadhuban.co.in:443/bill/payBill?pay=true',
        {
          amountPaid: parseFloat(amountPaid),
          paymentMode,
          reservationId: order.reservationId,
          orderId: order.id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data === 'Bill Updated!') {
        toast({
          title: "Payment Successful",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        // Refresh order details after payment
        fetchOrderDetails();
      } else {
        throw new Error('Failed to process payment');
      }
    } catch (error) {
      console.error('Failed to process payment:', error);
      toast({
        title: "Failed to process payment",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditOrder = (orderItem) => {
    setSelectedOrderItem(orderItem);
    setIsEditOrderSheetVisible(true);
  };

  const handleOrderUpdate = async (updatedItem) => {
    try {
      const response = await axios.put(
        `https://api.hotelmadhuban.co.in:443/order-item/edit?id=${updatedItem.id}`,
        {
          menuItemId: updatedItem.menuItem.id,
          quantity: updatedItem.quantity,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data === 'Edited Successfully!') {
        setOrder(prevOrder => ({
          ...prevOrder,
          orderItems: prevOrder.orderItems.map(item =>
            item.id === updatedItem.id ? updatedItem : item
          )
        }));
        toast({
          title: "Order updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        await fetchOrderDetails();
      } else {
        throw new Error('Failed to update the order');
      }
    } catch (error) {
      console.error('Failed to update order:', error);
      toast({
        title: "Failed to update order",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsEditOrderSheetVisible(false);
  };

  const handleDeleteOrderItem = async (orderItem) => {
    try {
      const response = await axios.delete(
        `https://api.hotelmadhuban.co.in:443/order-item/delete?orderId=${order.id}&orderItemId=${orderItem.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data === 'Object Deleted!') {
        setOrder(prevOrder => ({
          ...prevOrder,
          orderItems: prevOrder.orderItems.filter(item => item.id !== orderItem.id)
        }));
        toast({
          title: "Order item deleted successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error('Failed to delete the order item');
      }
    } catch (error) {
      console.error('Failed to delete order item:', error);
      toast({
        title: "Failed to delete order item",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsEditOrderSheetVisible(false);
  };

  const getOrderStatus = () => {
    let status = '';
    if (!order.isActive) status += "Completed";
    if (order.paidAmount >= order.totalOrderAmount) status += (status ? " / " : "") + "Paid";
    if (order.checkoutStatus) status += (status ? " / " : "") + "Checked Out";
    return status || "Ongoing";
  };

  const getPaymentStatusColor = () => {
    if (isBillFullyPaid) return "green";
    if (order.paidAmount > 0 || order.transferredToHotel > 0) return "yellow";
    return "red";
  };

  const handleOpenBillingModal = () => {
    setIsBillingModalOpen(true);
  };

  const handleCloseBillingModal = () => {
    setIsBillingModalOpen(false);
  };

  const isOrderCheckedOut = () => {
    return getOrderStatus().includes("Checked Out");
  };

  const handlePrint = () => {
    if (printBillRef.current) {
      const printContent = printBillRef.current.innerHTML;
      const originalContent = document.body.innerHTML;
      document.body.innerHTML = printContent;
      window.print();
      document.body.innerHTML = originalContent;
    }
  };

  const handlePaymentSuccess = () => {
    handleCloseBillingModal();
    fetchOrderDetails();
  };

  const handleAddToHotelRoom = async () => {
    if (!order.hotelBookingId) {
      toast({
        title: "Error",
        description: "No booking ID found for this order",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    const username = 'MadhubanAdmin';
    const password = 'Hotel2024admin';
    const bookingId = order.hotelBookingId;
    const foodAmount = order.pendingAmount;

    try {
      const response = await axios.put(
        `http://ec2-54-253-7-206.ap-southeast-2.compute.amazonaws.com:8081/hotel/addFoodBill`,
        null,
        {
          params: {
            username,
            password,
            bookingId,
            foodAmount,
            isGroup: false,
            orderId: order.id
          },
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );

      if (response.data === 'Food Bill Added Successfully') {
        toast({
          title: "Success",
          description: `Remaining bill amount added to booking ID ${bookingId}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        fetchOrderDetails(); // Refresh order details
      } else {
        throw new Error('Failed to add food bill');
      }
    } catch (error) {
      console.error('Error adding food bill:', error);
      toast({
        title: "Error",
        description: "Failed to add bill to the booking. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRoomSelection = (room) => {
    setSelectedRoom(room);
  };

  const handleConfirmRoomSelection = async () => {
    if (!selectedRoom) {
      toast({
        title: "Error",
        description: "Please select a room",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    const username = 'MadhubanAdmin';
    const password = 'Hotel2024admin';
    const bookingId = selectedRoom.bookingId;
    const foodAmount = order.totalOrderAmount;

    try {
      const response = await axios.put(
        `http://ec2-54-253-7-206.ap-southeast-2.compute.amazonaws.com:8081/hotel/addFoodBill`,
        null,
        {
          params: {
            username,
            password,
            bookingId,
            foodAmount,
          },
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );

      if (response.data === 'Food Bill Added Successfully') {
        toast({
          title: "Success",
          description: `Bill added to booking ID ${bookingId}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setIsRoomSelectionOpen(false);
        setSelectedRoom(null);
      } else {
        throw new Error('Failed to add food bill');
      }
    } catch (error) {
      console.error('Error adding food bill:', error);
      toast({
        title: "Error",
        description: "Failed to add bill to the booking. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getOrderType = (order) => {
    if (order.tableNumber) {
      return 'Restaurant';
    } else if (order.hotelBookingId) {
      return 'Hotel';
    } else if (order.isTakeaway) {
      return 'Takeaway';
    } else {
      return 'Restaurant'; // Default case
    }
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        `http://ec2-54-253-7-206.ap-southeast-2.compute.amazonaws.com:8081/waiter/order/delete?id=${order.id}`,
        {},
        {
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );
      if (response.data === 'Object Deleted!') {
        // Order deleted successfully, navigate back to the orders page
        history.push('/orders');
      } else {
        console.error('Error deleting order');
      }
    } catch (error) {
      console.error('Error deleting order:', error);
    }
  };

  const handleOpenHotelSearchModal = () => {
    setIsHotelSearchModalOpen(true);
  };

  const handleCloseHotelSearchModal = () => {
    setIsHotelSearchModalOpen(false);
    setSearchQuery('');
    setSearchResults([]);
    setSelectedBooking(null);
    setCurrentPage(1);
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `http://ec2-54-253-7-206.ap-southeast-2.compute.amazonaws.com:8081/hotel/getByCustomer`,
        {
          params: {
            username: 'MadhubanAdmin',
            password: 'Hotel2024admin',
            name: searchQuery,
          },
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );
      setSearchResults(response.data);
      setCurrentPage(1); // Reset to first page after new search
    } catch (error) {
      console.error('Error searching for bookings:', error);
      toast({
        title: "Error",
        description: "Failed to search for bookings. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSelectBooking = (booking) => {
    setSelectedBooking(booking);
  };

  const paginatedResults = searchResults.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(searchResults.length / itemsPerPage);

  const handleConfirmBookingSelection = async () => {
    if (!selectedBooking) {
      toast({
        title: "Error",
        description: "Please select a booking",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const isGroup = selectedBooking.groupId !== null;
      const response = await axios.put(
        `http://ec2-54-253-7-206.ap-southeast-2.compute.amazonaws.com:8081/hotel/addFoodBill`,
        null,
        {
          params: {
            username: 'MadhubanAdmin',
            password: 'Hotel2024admin',
            bookingId: selectedBooking.bookingId,
            foodAmount: order.totalOrderAmount,
            isGroup: isGroup,
            orderId: order.id  // Add this new parameter
          },
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );

      if (response.data === 'Food Bill Added Successfully') {
        toast({
          title: "Success",
          description: `Bill added to booking ID ${selectedBooking.bookingId}${isGroup ? ' (Group Booking)' : ''}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        handleCloseHotelSearchModal();
      } else {
        throw new Error('Failed to add food bill');
      }
    } catch (error) {
      console.error('Error adding food bill:', error);
      toast({
        title: "Error",
        description: "Failed to add bill to the booking. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return <Text>Loading bill details...</Text>;
  }

  if (!order) {
    return <Text>No order found.</Text>;
  }

  const calculateTotal = () => {
    const subtotal = order.orderItems.reduce((total, item) => total + item.menuItem.price * item.quantity, 0);
    const cgst = Math.round(subtotal * 0.025 * 100) / 100;
    const sgst = Math.round(subtotal * 0.025 * 100) / 100;
    const total = Math.round(subtotal + cgst + sgst);
    return { subtotal, cgst, sgst, total };
  };

  const { subtotal, cgst, sgst, total } = calculateTotal();
  
  return (
    <Flex minHeight="100vh">
      <Sidebar />
      <Box
        flex="1"
        p={4}
        bg={colorMode === 'dark' ? 'gray.800' : 'gray.50'}
        ml={{ base: 0, md: '250px' }}
        pb={{ base: '60px', md: 0 }}
      >
        <Flex align="center" justify="space-between" mb={4}>
          <Flex align="center">
            <IconButton
              icon={<FaArrowLeft />}
              onClick={handleBack}
              aria-label="Back"
              variant="ghost"
              color="current"
              _hover={{ bg: 'gray.200' }}
              mr={4}
            />
            <Text
              fontSize="3xl"
              fontWeight="bold"
              color={colorMode === 'dark' ? 'purple.400' : 'purple.600'}
            >
              Bill Details
            </Text>
            <Badge colorScheme="purple" ml={4}>
              {getOrderType(order)}
            </Badge>
            {order.tableNumber && (
              <Badge colorScheme="blue" ml={2}>
                Table {order.tableNumber}
              </Badge>
            )}
            {order.hotelBookingId && !order.tableNumber && (
              <Badge colorScheme="blue" ml={2}>
                Booking #{order.hotelBookingId}
              </Badge>
            )}
            {order.isTakeaway && (
              <Badge colorScheme="orange" ml={2}>
                Takeaway
              </Badge>
            )}
          </Flex>
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<FaEllipsisV />}
              variant="ghost"
              color="current"
              aria-label="More options"
            />
            <MenuList>
              <MenuItem onClick={onOpen}>
                Delete Order
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>

        <Flex direction={{ base: 'column', lg: 'row' }} spacing={6}>
          {isReception && (
            <Box flex="1" ml={{ base: 0, lg: 6 }} mt={{ base: 6, lg: 0 }}>
              <PrintBill orders={[order]} tableId={order.tableNumber} />
            </Box>
          )}
        </Flex>
        <Flex direction={{ base: 'column', lg: 'row' }} spacing={6}>
          {/* PrintBill component takes up 20% width on lg screens */}
          <Box flex={{ base: '100%', lg: '80%' }} mr={{ base: 0, lg: 6 }} mb={{ base: 6, lg: 0 }}>
            <Box
              bg={colorMode === 'dark' ? 'gray.700' : 'white'}
              p={4}
              borderRadius="lg"
              boxShadow="md"
              mb={6}
            >
              <StatGroup>
                <Stat>
                  <StatLabel>{getOrderType(order) === 'Hotel' ? 'Room' : 'Table'}</StatLabel>
                  <StatNumber>
                    <HStack>
                      <FaTable />
                      <Text>
                        {getOrderType(order) === 'Hotel'
                          ? (bookingDetails ? bookingDetails.roomNumber : 'Loading...')
                          : order.tableNumber}
                      </Text>
                    </HStack>
                  </StatNumber>
                </Stat>
                <Stat>
                  <StatLabel>Status</StatLabel>
                  <StatNumber>
                    {getOrderStatus().split(" / ").map((status, index) => (
                      <Badge
                        key={index}
                        colorScheme={status === "Ongoing" ? "yellow" : status === "Checked Out" ? "blue" : "green"}
                        mr={1}
                      >
                        {status}
                      </Badge>
                    ))}
                  </StatNumber>
                </Stat>
                <Stat>
                  <StatLabel>Order Time</StatLabel>
                  <StatNumber>
                    <HStack>
                      <FaClock />
                      <Text>{formatOrderTime(order.orderTime)}</Text>
                    </HStack>
                  </StatNumber>
                </Stat>
              </StatGroup>
            </Box>

            <AnimatePresence>
              {order.orderItems && order.orderItems.map((item, index) => (
                <MotionBox
                  key={item.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                  mb={6}
                >
                  <Box
                    bg={colorMode === 'dark' ? 'gray.700' : 'white'}
                    borderRadius="lg"
                    overflow="hidden"
                    boxShadow="md"
                    position="relative"
                    _before={{
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      height: '4px',
                      bgGradient: 'linear(to-r, purple.500, cyan.500)',
                    }}
                  >
                    <Box p={4}>
                      <Flex justify="space-between" align="center" mb={2}>
                        <HStack>
                          <Text>{item.menuItem?.dietInfo === 'Vegetarian' ? <FaLeaf /> : <FaHamburger />}</Text>
                          <Text fontWeight="bold">{item.menuItem?.name || 'Unknown Item'}</Text>
                        </HStack>
                        <Text fontSize="sm" color={colorMode === 'dark' ? 'gray.400' : 'gray.600'}>
                          x {item.quantity || 0}
                        </Text>
                      </Flex>
                      <Flex justify="space-between" align="center">
                        <Text fontWeight="bold">₹{(item.menuItem?.price * item.quantity || 0).toFixed(2)}</Text>
                        <Button size="sm" leftIcon={<FaEdit />} onClick={() => handleEditOrder(item)}>
                          Edit
                        </Button>
                      </Flex>
                      {item.specialInstructions && (
                        <Text fontSize="sm" color="gray.500" mt={2}>
                          Note: {item.specialInstructions}
                        </Text>
                      )}
                    </Box>
                  </Box>
                </MotionBox>
              ))}
            </AnimatePresence>
            <Divider my={4} />
            <Box>
              <Flex justify="space-between" mb={2}>
                <Text>Subtotal:</Text>
                <Text>₹{order.totalOrderAmount.toFixed(2)}</Text>
              </Flex>
              <Flex justify="space-between" fontWeight="bold">
                <Text>Total:</Text>
                <Text>₹{(order.totalOrderAmount - order.discount).toFixed(2)}</Text>
              </Flex>
            </Box>
            <Divider my={4} />
            <Box>
              <Text fontWeight="bold" mb={2}>Payment Status</Text>
              <Progress value={((order.paidAmount + order.transferredToHotel) / order.totalOrderAmount) * 100} colorScheme={getPaymentStatusColor()} mb={2} />
              <Flex justify="space-between">
                <Text>Paid: ₹{(order.paidAmount + order.transferredToHotel).toFixed(2)}</Text>
                <Text>Pending: ₹{order.pendingAmount.toFixed(2)}</Text>
              </Flex>
              {isBillFullyPaid && (
                <Badge colorScheme="green" mt={2}>
                  Fully Paid
                </Badge>
              )}
              {isBillFullyTransferred && (
                <Badge colorScheme="blue" mt={2} ml={isBillFullyPaid ? 2 : 0}>
                  Fully Transferred to Hotel
                </Badge>
              )}
            </Box>

            {isReception && !isBillFullyPaid && !isBillFullyTransferred ? (
              <Box mt={6}>
                <Select
                  value={paymentMode}
                  onChange={(e) => setPaymentMode(e.target.value)}
                  mb={4}
                >
                  <option value="cash">Cash</option>
                  <option value="card">Card</option>
                  <option value="upi">UPI</option>
                </Select>
                <Input
                  type="number"
                  placeholder="Amount Paid"
                  value={amountPaid}
                  onChange={(e) => setAmountPaid(e.target.value)}
                  mb={4}
                />
                <Button
                  onClick={handlePayment}
                  isLoading={isLoading}
                  colorScheme="green"
                  width="full"
                >
                  Process Payment
                </Button>
              </Box>
            ) : (
              <Flex direction={{ base: 'column', lg: 'row' }} mt={6} gap={4}>
                {!isOrderCheckedOut() && !isHotelOrder && !isTakeaway && (
                  <Button
                    onClick={handleCheckout}
                    isLoading={isLoading}
                    colorScheme="blue"
                    width={{ base: 'full', lg: 'auto' }}
                  >
                    Checkout
                  </Button>
                )}
                {role !== 'WAITER' && (
                  <>
                    <Button
                      onClick={handleOpenBillingModal}
                      isLoading={isLoading}
                      colorScheme="green"
                      width={{ base: 'full', lg: 'auto' }}
                      isDisabled={isBillFullyPaid || isBillFullyTransferred}
                    >
                      Pay Now
                    </Button>
                    <Button
                      onClick={isHotelOrder ? handleAddToHotelRoom : handleOpenHotelSearchModal}
                      isLoading={isLoading}
                      colorScheme="purple"
                      width={{ base: 'full', lg: 'auto' }}
                      isDisabled={isBillFullyTransferred || !isPendingAmount}
                    >
                      Add to Hotel Room
                    </Button>
                    <Button
                      onClick={handlePrint}
                      isLoading={isLoading}
                      colorScheme="orange"
                      width={{ base: 'full', lg: 'auto' }}
                    >
                      Print
                    </Button>
                  </>
                )}
              </Flex>
            )}
          </Box>

          {role !== 'WAITER' && (
            <div ref={printBillRef}>
              <PrintBill orders={[order]} tableId={order.tableNumber} />
            </div>
          )}
        </Flex>
      </Box>

      <Modal
        isOpen={isEditOrderSheetVisible}
        onClose={() => setIsEditOrderSheetVisible(false)}
        isCentered
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <AnimatePresence>
          {isEditOrderSheetVisible && selectedOrderItem && (
            <MotionModalContent
              initial={{ y: "100%" }}
              animate={{ y: 0 }}
              exit={{ y: "100%" }}
              transition={{ type: "spring", damping: 25, stiffness: 500 }}
              borderTopRadius={isMobile ? "20px" : "md"}
              borderBottomRadius={isMobile ? "0" : "md"}
              bg={colorMode === 'dark' ? 'gray.800' : 'white'}
            >
              <ModalHeader borderBottomWidth="1px">Edit Order</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack spacing={6} align="stretch">
                  <HStack justify="space-between">
                    <Text fontSize="lg" fontWeight="bold">{selectedOrderItem.menuItem?.name || 'Unknown Item'}</Text>
                    <HStack>
                      <IconButton
                        icon={<FaMinus />}
                        onClick={() => {
                          if (selectedOrderItem.quantity > 1) {
                            setSelectedOrderItem({
                              ...selectedOrderItem,
                              quantity: selectedOrderItem.quantity - 1
                            });
                          }
                        }}
                        isDisabled={selectedOrderItem.quantity <= 1}
                        variant="outline"
                      />
                      <Text fontSize="lg" fontWeight="bold">{selectedOrderItem.quantity}</Text>
                      <IconButton
                        icon={<FaPlus />}
                        onClick={() => {
                          setSelectedOrderItem({
                            ...selectedOrderItem,
                            quantity: selectedOrderItem.quantity + 1
                          });
                        }}
                        variant="outline"
                      />
                    </HStack>
                  </HStack>
                  <HStack justify="space-between">
                    <Button
                      onClick={() => {
                        handleOrderUpdate(selectedOrderItem);
                        setIsEditOrderSheetVisible(false);
                      }}
                      colorScheme="blue"
                      flex={1}
                    >
                      Save Changes
                    </Button>
                    <IconButton
                      icon={<FaTrash />}
                      onClick={() => {
                        handleDeleteOrderItem(selectedOrderItem);
                        setIsEditOrderSheetVisible(false);
                      }}
                      colorScheme="red"
                      aria-label="Delete item"
                    />
                  </HStack>
                </VStack>
              </ModalBody>
            </MotionModalContent>
          )}
        </AnimatePresence>
      </Modal>

      <Modal isOpen={isRoomSelectionOpen} onClose={() => setIsRoomSelectionOpen(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select a Room</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={3} spacing={4}>
              {checkedInBookings.map((booking) => (
                <MotionBox
                  key={booking.bookingId}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleRoomSelection(booking)}
                  bg={selectedRoom?.bookingId === booking.bookingId ? 'purple.500' : 'gray.100'}
                  color={selectedRoom?.bookingId === booking.bookingId ? 'white' : 'black'}
                  p={4}
                  borderRadius="md"
                  cursor="pointer"
                  boxShadow="md"
                >
                  <VStack align="start" spacing={2}>
                    <Text fontWeight="bold">Room {booking.roomNumber}</Text>
                    <Text fontSize="sm">{booking.customerName}</Text>
                    <Text fontSize="xs">Check-in: {booking.checkIn}</Text>
                  </VStack>
                </MotionBox>
              ))}
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleConfirmRoomSelection}>
              Confirm Selection
            </Button>
            <Button variant="ghost" onClick={() => setIsRoomSelectionOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {isBillingModalOpen && (
      <BillingModal
      order={order}
      isOpen={isBillingModalOpen}
      onClose={handleCloseBillingModal}
      token={token}
      onPaymentSuccess={handlePaymentSuccess}
    />
      )}

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Order
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this order? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Hotel Search Modal */}
      <Modal isOpen={isHotelSearchModalOpen} onClose={handleCloseHotelSearchModal} size="6xl">
        <ModalOverlay />
        <ModalContent bg={modalBg}>
          <ModalHeader>Search Hotel Bookings</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <HStack width="100%">
                <Input
                  placeholder="Search by customer name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  flex={1}
                />
                <Button onClick={handleSearch} colorScheme="blue">
                  Search
                </Button>
              </HStack>
              <SimpleGrid columns={3} spacing={4} width="100%">
                {paginatedResults.map((booking) => (
                  <Card
                    key={booking.bookingId}
                    onClick={() => handleSelectBooking(booking)}
                    cursor="pointer"
                    bg={selectedBooking?.bookingId === booking.bookingId ? selectedCardBg : cardBg}
                    borderColor={cardBorderColor}
                    borderWidth="1px"
                    boxShadow="md"
                    transition="all 0.2s"
                    _hover={{ transform: 'scale(1.02)' }}
                  >
                    <CardBody>
                      <VStack align="start" spacing={2}>
                        <HStack justify="space-between" width="100%">
                          <Tag colorScheme="purple">#{booking.bookingId}</Tag>
                          <Tag colorScheme="green">Room {booking.roomNumber}</Tag>
                          {booking.groupId && (
                            <Tooltip label="Group Booking">
                              <Tag colorScheme="blue">
                                <Icon as={FaUsers} mr={1} />
                                Group
                              </Tag>
                            </Tooltip>
                          )}
                        </HStack>
                        <Text fontWeight="bold" fontSize="lg">{booking.customerName}</Text>
                        <Text fontSize="sm" color="gray.500">{booking.phoneNumber}</Text>
                        <HStack spacing={4}>
                          <HStack>
                            <Icon as={FaCalendarAlt} color="blue.500" />
                            <Text fontSize="sm">Check-In: {booking.checkIn}</Text>
                          </HStack>
                          <HStack>
                            <Icon as={FaCalendarAlt} color="red.500" />
                            <Text fontSize="sm">Check-Out: {booking.checkOut}</Text>
                          </HStack>
                        </HStack>
                      </VStack>
                    </CardBody>
                  </Card>
                ))}
              </SimpleGrid>
              {totalPages > 1 && (
                <HStack justifyContent="center" width="100%" mt={4}>
                  <Button
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                    isDisabled={currentPage === 1}
                  >
                    Previous
                  </Button>
                  <Text>{`Page ${currentPage} of ${totalPages}`}</Text>
                  <Button
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                    isDisabled={currentPage === totalPages}
                  >
                    Next
                  </Button>
                </HStack>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleConfirmBookingSelection}>
              Confirm Selection
            </Button>
            <Button variant="ghost" onClick={handleCloseHotelSearchModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default BillPage;
